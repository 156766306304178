var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "grey lighten-4" },
    [
      _c("SubHeader", { attrs: { id: "v-step-0" } }),
      _c("div", { attrs: { id: "tourContainer" } }),
      _vm.showRegistration
        ? _c("Registration", {
            on: { SubmitReg: _vm.showRegistrationMessage },
            model: {
              value: _vm.showRegistration,
              callback: function($$v) {
                _vm.showRegistration = $$v
              },
              expression: "showRegistration"
            }
          })
        : _vm._e(),
      _c("InfoMessage", {
        attrs: { msg: _vm.infoMessage, title: _vm.infoMessageTitle },
        model: {
          value: _vm.showInfoMsg,
          callback: function($$v) {
            _vm.showInfoMsg = $$v
          },
          expression: "showInfoMsg"
        }
      }),
      _c("MainContent"),
      _c("v-tour", {
        attrs: {
          name: "timberTour",
          steps: _vm.steps,
          options: { highlight: true }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(tour) {
              return [
                _c(
                  "transition",
                  { attrs: { name: "fade" } },
                  [
                    tour.steps[tour.currentStep]
                      ? _c(
                          "v-step",
                          {
                            key: tour.currentStep,
                            attrs: {
                              step: tour.steps[tour.currentStep],
                              "previous-step": tour.previousStep,
                              "next-step": tour.nextStep,
                              stop: tour.stop,
                              skip: _vm.onSkipTour,
                              finish: _vm.onFinishTour,
                              "is-first": tour.isFirst,
                              "is-last": tour.isLast,
                              highlight: tour.highlight,
                              labels: tour.labels
                            }
                          },
                          [
                            tour.currentStep === 0
                              ? [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "actions" },
                                      slot: "actions"
                                    },
                                    [
                                      _c("br"),
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "v-step__buttons",
                                          attrs: { "no-gutters": "" }
                                        },
                                        [
                                          _c("v-spacer"),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "auto" } },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "v-step__button v-step__button-skip",
                                                  on: { click: _vm.onSkipTour }
                                                },
                                                [_vm._v("Skip tour")]
                                              ),
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "v-step__button v-step__button-next",
                                                  on: { click: tour.nextStep }
                                                },
                                                [_vm._v("Start tour >")]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }