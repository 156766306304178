



































export default {
  name: "EmailVerify",
  props: ["id"],
  data() {
    return {
      working: true,
      apiMsg: null,
      redirectSec: 10,
      verifyImg: require("@/assets/email-check.png"),
      verifySuccess: true,
    };
  },
  async mounted() {
    const userId = this.$route.params.id;
    if (userId) {
      var msg = await this.$store.dispatch("datafields/verifyUser", [userId]);
      this.working = false;
      this.verifySuccess = msg && msg.ErrorNumber == 0;
      this.apiMsg =
        msg && msg.ErrorNumber == 0
          ? "Thank you for verifying your email!"
          : msg
          ? msg.ResultMessage
          : "An unknown error has occurred. Please contact WSP Support";
       setTimeout( () => this.$router.push({ path: '/'}), 5000);
    } else {
      this.working = false;
    }
  },
};
