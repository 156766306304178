








































    
export default {
    name: "app",
    data() {
        return {    
            initReg: true,
            showRegistration: false,
            steps: [
                {
                    target: '#tourContainer',                        
                    content: `<p class="brand-tour-label">Welcome to <br><span class="brandSpan-tour">timber<b>X</b></span></p>Use this tool at project concept stage to understand the merits of mass timber in structural material selection. `,
                    params: {
                        highlight: false
                    }
                },
                {
                    target: '#tourContainer',
                    content: `<br><p><b>This is a concept design tool.</b> It is intended to guide those involved in the early stages of a project, through the process of material selection by providing high level insight and comparative outcomes of timber, concrete and structural steel systems.</p><p><b>This is not a detailed design tool.</b> Buildings are complex and their successful design depends on several technical, market, and user implications that impact geometry, system integration, schedule, and price. Since the tool represents a simplified and virtual isolated frame, the outcomes of the tool should not be taken as absolute, but rather used as an informed starting point for concept development.</p> `,
                    params: {
                        highlight: false
                    }
                },
                {
                    target: '#tourContainer',
                    content: '<br><p>The tool allows the user to select a standard structural bay of a single typical floor and assess the design implications. The user can define the size of the bay and the use of the building to develop the outcomes.</p>' +
                        '<p>The tool summarizes 4 primary outcomes:</p><ul class="tour-list"><li>The weight of the system</li><li>The overall structural depth of the system</li><li>The approximate cost of the structure only</li><li>The approximate embodied carbon of the structure</li></ul><br><p>Please read the detailed assumptions section for more detailed notes on the tool itself, as well as each of these outcomes</p>',
                    params: {
                        highlight: false
                    }
                },
                {
                    target: '.v-step-4',
                    content: '<br>On the top bar, select the width and length of the typical bay you would like to investigate. '
                },
                {
                    target: '.v-step-5',
                    content: '<br>Also on the top bar, select your sector or the building use. The sectors are described in more detail in the General Info Menu. '
                },
                {
                    target: '.v-step-6',
                    content: '<br><p>The cards on the left show the results of the four primary outcomes, including</p><ul class="tour-list"><li>bay weight</li><li>material quantities,</li><li>conceptual costing,</li><li>and embodied carbon data,</li></ul><p>all on a per square metre basis.</p><br>' +
                        '<p>The crown symbol <i class="v-icon notranslate mdi mdi-crown gold-crown"></i> indicates which of the 3 options displayed on the cards performs the best for the given metric, while the 2 and 3 superscripts represent 2nd and 3rd in the ranking.</p><p>Material specific assumptions built into the tool can be found by clicking the info menu at the top right of each card. </p>',
                    params: {
                        placement: 'right'
                    }
                },                   
                {
                    target: '.v-step-7',
                    content: 'Within the timber card, manually choose from up to 7 different timber systems, and the results will automatically update. The systems are more thoroughly described in the Info Menu. ',
                    params: {
                        placement: 'right'                            
                    }
                },
                {
                    target: '.v-step-7a',
                    content: 'Click the Auto-Select box for the timber card to display the timber solution with optimal performance.'                        
                },
                {
                    target: '.v-step-8',
                    content: 'The 3D model on the bottom of the cards gives a simplified view of the system. The model is interactive, allowing the user to rotate the model to view from different angles, as well as the ability to expand to full screen and reset view using the icons in the bottom right corner of the 3D view. ',
                    params: {
                        placement: 'right'                            
                    }
                },
                {
                    target: '.v-step-9',
                    content: 'On the top right, view a comparison of the 3 selected systems in the radar chart, displaying the trade-offs in bay weight, structural depth, approximate cost, and approximate embodied carbon. The closer to the centre of the radar chart, the better the solution.',
                    params: {
                        placement: 'left'
                    }
                },
                {
                    target: '.v-step-10',
                    content: 'On the bottom right, a table is provided to see how all 9 systems (7 timber, 1 concrete and 1 steel) compare on the metric that is more important to you. The systems are described in more detail in the info pane. You can also manually choose one of the 7 timber options available by clicking the option in this table and the timber card will automatically update. ',
                    params: {
                        placement: 'left'
                    }
                },
                {
                    target: '.v-step-11',
                    content: '<p>For more information on the general assumptions built into the tool, and to launch this tour again, click the General Info Menu here.</p>',
                    params: {
                        placement: 'left'
                    }
                }
            ],
            showInfoMsg: false,
            infoMessageTitle: 'A verification link has been sent to your email account',
            infoMessage: "",
        }
    },
    components: {            
        SubHeader: () => import('./SubHeader.component.vue'),
        MainContent: () => import('./MainContent.component.vue'),
        Registration: () => import('./Registration.component.vue'),
        InfoMessage: () => import("./InfoMessage.vue")
    },
    created() {            
    },          
    computed: {
    },
    async mounted() {            
        await this.$store.dispatch("misc/user_IP");
        await this.checkRegistration();
    },
    methods: {
        onFinishTour() {
            this.$tours["timberTour"].finish();
            if (this.initReg) { this.initRegistration(); }
        },
        onSkipTour() {
            this.$tours["timberTour"].skip();
            if (this.initReg) { this.initRegistration(); }
        },
        //onHideTour() {
        //    localStorage.setItem('timbertour', 'false');
        //    this.$tours["timberTour"].finish();
        //},        
        async checkRegistration() {
            var reg = await this.$store.getters["misc/getRegistrationStore"];
            if (reg) {
                this.initReg = false;
                return;
            }    
            if (this.$store.state.misc.userIP) {
                var user = await this.$store.dispatch("datafields/getUser", [this.$store.state.misc.userIP, '1']);
                if (user && user.data && user.data.length > 0) {
                    this.$store.dispatch("misc/setRegistrationStore", { val: "1" });
                    this.initReg = false;
                    return;
                }                    
            }
        },
        initRegistration() {
            var _t = this;
            setTimeout(function () {           
                _t.showRegistration = true;     
            }, 20000);                
        },
        showRegistrationMessage() {
            this.showInfoMsg = true;
            this.infoMessage = 'Please click on the link that has just been sent to your email account to verify your email and continue the registration process.';
        }
        
    }
}
