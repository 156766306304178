import UserModel from './UserModel'

export default class AuthenticationModel {
  Status: string;
  Token: string;
  User: UserModel | null;

  constructor () {
    this.Status = ''
    this.Token = localStorage.getItem('token') || ''
    this.User = null
  }
}
