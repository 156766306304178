import axios from 'axios'
import Vue from 'vue'

export default {
    namespaced: true,
    data: () => ({
        //appApiUrl: process.env.VUE_APP_API_URL,
        //appApiUrl: process.env.VUE_APP_API_URL //'http://localhost:44300/api',
    }),
    state: {
        appApiUrl: process.env.VUE_APP_API_URL,
        loading: false,
        error: null,
        materialName: '',
        selectedBayX: '3',
        selectedBayY: '3',
        selectedSector: '',
        bestTimberMaterial: { 'rating': '', 'text': '' },
        selectedTimberMaterial: { 'text': '', 'textVal': '' },
        selectedConcreteMaterial: { 'text': '' },
        selectedSteelMaterial: { 'text': '' },
        // json list structured as {'text': 'value'}
        materialTypes: [{ 'text': 'Timber' }, { 'text': 'Concrete' }, { 'text': 'Steel' }],
        timberNames: [{ 'text': '' }],
        timberDetails: [{ 'id': '' }],
        concreteNames: [{ 'text': '' }],
        concreteDetails: [{ 'id': '' }],
        steelNames: [{ 'text': '' }],
        steelDetails: [{ 'id': '' }],
        activeMaterialObject: null,
        timberMaterialObject: null,
        concreteMaterialObject: null,
        steelMaterialObject: null,
        activeMaterial: 'Timber',
        timberObjectUri: '',
        concreteObjectUri: '',
        steelObjectUri: '',
        objMaterialsUri: '',
        materialDimensions: {
            "depth": 10
            , "height": 10
            , "width": 10
        },
        cameraTimberSaved: {}, // position, rotation, target
        cameraConcreteSaved: {}, // position, rotation, target
        cameraSteelSaved: {}, // position, rotation, target

        timberTexture: null,
        timberTextureFile: 'models/textures/timber.jpg',
        concreteTexture: null,
        concreteTextureFile: 'models/textures/concrete.jpg',
        steelTexture: null,
        steelTextureFile: 'models/textures/steel.jpg',

        resources: 'models/',
        materialsToLoad: [
            'materials.mtl'
        ],
        loadedMaterials: [],


        // can create from database query of DataProperties table
        // are used as labels for each item
        //                 'Material Efficiency': '%',
        dataSuffix: [
            {
                'Bay Weight': 'kg/m²',
                'Structural Depth': 'mm',
                'Thickness of Concrete': 'mm',
                'Glulam Purlin Size': '(mm x mm)',
                'Glulam Girder Size': '(mm x mm)',
                'Glulam Grade': '',
                'Timber Volume': 'm³/m²',
                'Concrete Volume': 'm³/m²',
                'Concrete Reinforcement': 'kg/m²',
                'Slab Thickness': 'mm',
                'Drop Panel Size': '(mm x mm)',
                'Drop Panel Thickness': 'mm',
                'Formwork Quantity': 'm²/m²',
                'Floor Deck Type': '',
                'Steel Purlin Size': '',
                'Steel Girder Size': '',
                'Steel Joist and Girder Tonnage': 'kg/m²',
                'Steel Deck Tonnage': 'kg/m²',
                'Carbon': 'kgCO₂/m²',
                'Cost': '/m²'
            }
        ],
        dataPrefix: [
            {
                'Cost': '$'
            }
        ],
        dataComma: [
            {
                'Bay Weight': true,
                'Structural Depth': true,
                'Cost': true,
                'Carbon': true
            }
        ],
        // can create from database query of DataProperties table
        itemPrefix: [
            {
                'BayUnitWeight': '',
                'OverallStructuralDepth': '',
                'Cost': '$ ',
                'Carbon': '',
                //'MaterialEfficiency': ''
            }
        ],
        // can create from database query of DataProperties table
        itemSuffix: [
            {
                'BayUnitWeight': 'kg/m²',
                'OverallStructuralDepth': 'mm',
                'Cost': '/m²',
                'Carbon': 'kgCO₂/m²',
                //'MaterialEfficiency': '%'
            }
        ],
        // can create from database query of DataProperties table
        itemComma: [
            {
                'BayUnitWeight': true,
                'OverallStructuralDepth': true,
                'Cost': true,
                'Carbon': true,
                //'MaterialEfficiency': false
            }
        ],
        chartMinUnit: 0.0,
        chartMaxUnit: 5.0,
        // true column names in SQL database table: DataList
        itemMaxFactor: [
            {
                'BayUnitWeight': 0,
                'OverallStructuralDepth': 0,
                'Cost': 0,
                'Carbon': 0,
                //'MaterialEfficiency': 0
            }
        ],
        // used to switch the max with min and offset all other values so the minimum is on the outside of chart
        itemMinValue: [
            {
                'BayUnitWeight': 0,
                'OverallStructuralDepth': 0,
                'Cost': 0,
                'Carbon': 0,
                //'MaterialEfficiency': 0
            }
        ],
        itemMaxValue: [
            {
                'BayUnitWeight': 0,
                'OverallStructuralDepth': 0,
                'Cost': 0,
                'Carbon': 0,
                //'MaterialEfficiency': 0
            }
        ],
        itemLookup: [
            {
                'BayUnitWeight': 'Bay Weight',
                'OverallStructuralDepth': 'Structural Depth',
                'Cost': 'Cost',
                'Carbon': 'Carbon',
                //'MaterialEfficiency': 'Material Efficiency',
            }
        ],
        itemReverseLookup: [
            {
                'Bay Weight': 'BayUnitWeight',
                'Structural Depth': 'OverallStructuralDepth',
                'Cost': 'Cost',
                'Carbon': 'Carbon',
                //'Material Efficiency': 'MaterialEfficiency',
            }
        ],
        itemOrder: ['BayUnitWeight', 'OverallStructuralDepth', 'Cost', 'Carbon'], //, 'MaterialEfficiency'],
        itemBestRank: ['Min', 'Min', 'Min', 'Min'], //, 'Max'
        itemRatingSorted: [],
        // fifth value of all arrays removed since MaterialEfficiency was removed
        // set for chart range from 0 to 5
        timberChartData: [0, 0, 0, 0],
        // used for the actual value and the units for the tooltip
        timberChartTrueValue: ["", "", "", ""],
        concreteChartData: [0, 0, 0, 0],
        concreteChartTrueValue: ["", "", "", ""],
        steelChartData: [0, 0, 0, 0],
        steelChartTrueValue: ["", "", "", ""],
        optionSummary: [],
        isAutoSelectEnabled: true,
        optionSystemTypeSelected: null,
        timberTypeSelection: null
    },
    getters: {
        data: state => state.data,
        loading: state => state.loading,
        error: state => state.error,
        getMaterialTypes: (state, getters) => {
            return state.materialTypes;
        },
        getTimberNames: (state, getters) => {
            return state.timberNames;
        },
        getBestTimberMaterial: (state, getters) => {
            //console.log("***** materials->getBestTimberMaterial()");           
            return state.bestTimberMaterial;
        },
        getConcreteNames: (state, getters) => {
            return state.concreteNames;
        },
        getSteelNames: (state, getters) => {
            return state.steelNames;
        },
        getMaterialNames: (state, getters) => (materialType) => {
            switch (materialType) {
                case "Timber":
                    return state.timberNames;
                case "Concrete":
                    return state.selectedConcreteMaterial.text;
                case "Steel":
                    return state.steelNames && state.steelNames.length > 0 ? state.steelNames[0].text : '';
            }
        },
        getTimberMaterial: (state, getters) => {
            return state.selectedTimberMaterial;
        },
        getActiveTimberName: (state, getters) => {
            return state.selectedTimberMaterial;
        },
        getMaterialRatingByKey: (state, getters) => (materialType, key) => {
            //console.log("***** materials->getMaterialRatingByKey()")
            var rating = 0;

            // make a separate action for setMaterialRating and use sorted array here to get rating
            
            var detailTimberValue = state.timberDetails && state.timberDetails.length > 0 ? state.timberDetails[0][key] : undefined;
            var detailConcreteValue = state.concreteDetails && state.concreteDetails.length > 0 ? state.concreteDetails[0][key] : undefined;
            var detailSteelValue = state.steelDetails && state.steelDetails.length > 0 ? state.steelDetails[0][key] : undefined;

            if (detailTimberValue !== undefined && detailConcreteValue !== undefined && detailSteelValue !== undefined) {
                var keyLookup = state.itemReverseLookup[0][key];
                var itemIndex = state.itemOrder.indexOf(keyLookup);
                var itemRank = state.itemBestRank[itemIndex];

                var itemArray = [];
                itemArray.push(
                    { 'materialType': 'Timber', 'value': Number(detailTimberValue) },
                    { 'materialType': 'Concrete', 'value': Number(detailConcreteValue) },
                    { 'materialType': 'Steel', 'value': Number(detailSteelValue) }
                )
                var sorted = [];
                if (itemRank === 'Min') {
                    sorted = itemArray.sort((a, b) => a.value - b.value);
                } else { //Max
                    sorted = itemArray.sort((a, b) => b.value - a.value);
                }
                rating = sorted.findIndex(x => x.materialType === materialType) + 1;
            }
            return rating;
        },
        getMaterialValueByKey: (state, getters) => (materialType, key) => {
            ////console.log("***** materials->getTimberValueByKey()")
            // https://www.whatsmyip.org/html-characters/  for special characters like ² = #178
            //state.timberDetails["Bay Weight"] = "300 kg/m²";
            if (!key || key.length == 0) { return '';}
            var detailValue;
            switch (materialType) {
                case 'Timber':
                    detailValue = state.timberDetails && state.timberDetails.length > 0 ? state.timberDetails[0][key] : undefined;
                    break;
                case 'Concrete':
                    detailValue = state.concreteDetails && state.concreteDetails.length > 0 ? state.concreteDetails[0][key] : undefined;
                    break;
                case 'Steel':
                    detailValue = state.steelDetails && state.steelDetails.length > 0 ? state.steelDetails[0][key] : undefined;
                    break;
            }
            if (key !== 'Assumptions') {
                // this is repeated as don't know how to use common method in a store
                if (detailValue !== undefined && detailValue.trim() !== '') {
                    detailValue = detailValue.trim();
                    var comma = state.dataComma[0][key];
                    if (comma !== undefined && comma === true) {
                        detailValue = detailValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }
                    var prefix = state.dataPrefix[0][key];
                    if (prefix !== undefined) {
                        detailValue = prefix.trim() + ' ' + detailValue;
                    }
                    var suffix = state.dataSuffix[0][key];
                    if (suffix !== undefined) {
                        if (suffix.substring(0, 1) !== '/') {
                            detailValue = detailValue + ' '
                        }
                        detailValue = detailValue + suffix.trim();
                    }
                } else { detailValue = '' }
            }

            return detailValue;
        },
        getChartDataByMaterialType: (state, getters) => (materialType) => {
            //console.log("***** materials->getChartDataByMaterialType()")
            var value;
            switch (materialType) {
                case 'Timber':
                    value = state.timberChartData
                    break;
                case 'Concrete':
                    value = state.concreteChartData
                    break;
                case 'Steel':
                    value = state.steelChartData
                    break;
            }
            return value;
        },
        getChartTrueValueByMaterialType: (state, getters) => (materialType) => {
            //console.log("***** materials->getChartTrueValueByMaterialType()")
            //console.log(state.timberChartTrueValue)
            var value;
            switch (materialType) {
                case 'Timber':
                    value = state.timberChartTrueValue
                    break;
                case 'Concrete':
                    value = state.concreteChartTrueValue
                    break;
                case 'Steel':
                    value = state.steelChartTrueValue
                    break;
            }
            return value;
        },
        getOptionSummary: (state, getters) => {
            //console.log("***** materials->getOptionSummary()")
            return state.optionSummary;
        },
        getMaterialObject({ state, dispatch, getters, commit }, [material]) {
            switch (material) {
                case 'Timber':
                    return state.timberMaterialObject = value;
                    break;
                case 'Concrete':
                    return state.concreteMaterialObject = value;
                    break;
                case 'Steel':
                    return state.steelMaterialObject = value;
                    break;
                case 'Active':
                    return state.activeMaterialObject = value;
                    break;
            }
        },
        getMaterialUri: (state, getters) => (materialType) => {
            if (materialType === 'Timber') {
                return state.timberObjectUri;
            } else if (materialType === 'Concrete') {
                return state.concreteObjectUri;
            } else if (materialType === 'Steel') {
                return state.steelObjectUri;
            } else if (materialType === 'materials.mtl') {
                return state.objMaterialsUri;
            }
        }
    },
    methods: {
        checkValue(value) {
            if (value !== undefined && value.trim() !== '') {
                value = value.trim();
                var units = state.dataSuffix[0][key];
                if (units !== undefined) {
                    if (units.substring(0, 1) !== '/') {
                        value = value + ' '
                    }
                    value = value + units.trim();
                }
            } else { value = '' }
            return value;
        }
    },
    mutations: {
        // use payload if multiple variables, other than state, are necessary
        //async setMaterialUri(state, payload) {
        /*
        async setMaterialUri(state, materialType) {
            var drawingName = '';
            if (materialType === 'Timber') {
                drawingName = state.timberDetails[0]['DrawingName']
                //drawingName = "Mesh1.obj"
            } else if (materialType === 'Concrete') {
                drawingName = state.concreteDetails[0]['DrawingName']
                //drawingName = "Mesh1.obj"
            } else if (materialType === 'Steel') {
                drawingName = state.steelDetails[0]['DrawingName']
            }

            await axios.post(state.appApiUrl + '/FileStorage/FileUri', { FolderName: 'obj', FileName: drawingName })
                .then(async response => {
                    //console.log(response);
                    if (materialType === 'Timber') {
                        state.timberObjectUri = response.data;
                    } else if (materialType === 'Concrete') {
                        state.concreteObjectUri = response.data;
                    } else if (materialType === 'Steel') {
                        state.steelObjectUri = response.data;
                    }
                    //await Promise.resolve(response);

                    return await new Promise((resolve) => {
                        setTimeout(() => {
                            resolve(true)
                        }, 10)
                    })

                })
                .catch(error => {
                    console.log(error);
                })
        }
        */
    },
    actions: {
        loadMaterialLibrary({ state, dispatch, getters, commit }) {
            var manager = new THREE.LoadingManager();
            var loader = new THREE.TextureLoader(manager);


        },
        setIsAutoSelectEnabled({ state, dispatch, getters, commit }, value) {
            //state.isAutoSelectEnabled = value;
            if (state.isAutoSelectEnabled) {
                dispatch('setBestTimberMaterial')
            }
        },
        setMaterialObject({ state, dispatch, getters, commit }, [material, value]) {
            switch (material) {
                case 'Timber':
                    state.timberMaterialObject = value;
                    break;
                case 'Concrete':
                    state.concreteMaterialObject = value;
                    break;
                case 'Steel':
                    state.steelMaterialObject = value;
                    break;
                case 'Active':
                    state.activeMaterialObject = value;
                    break;
            }
        },
        setBayX({ state, dispatch, getters, commit }, [value]) {
            state.selectedBayX = value.toString();
        },
        setBayY({ state, dispatch, getters, commit }, [value]) {
            state.selectedBayY = value.toString();
        },
        setSector({ state, dispatch, getters, commit }, value) {
            //console.log("***** materials->setSector()")
            //console.log(value)
            state.selectedSector = value.toString();
        },
        async setTimberMaterial({ state, dispatch, getters, commit }, [value]) {
            //console.log("***** materials->setTimberMaterial()")
            //console.log(value)
            await Promise.resolve(state.selectedTimberMaterial = value);            
            return await new Promise((resolve) => {
                setTimeout(() => {
                    resolve(true)
                }, 10)
            })
        },
        // being used for database content retrieval
        async setMaterialNames({ state, dispatch, getters, commit }, [materialType, valueName, val]) {
            //console.log("***** materials->setMaterialNames()")
            // make call to BE for database contents
            await axios({
                method: 'post',
                url: process.env.VUE_APP_API_URL + '/Values/MaterialNames',
                data: {
                    Value1: materialType,
                    Value2: valueName,
                    Value3: val
                }
            })
                .then(async function (response) {
                    //console.log(response);
                    switch (materialType) {
                        case 'Timber':
                            state.timberNames = response.data && response.data.length > 0 ? response.data : [];
                            break;
                        case 'Concrete':
                            state.concreteNames = response.data && response.data.length > 0 ? response.data : [];
                            break;
                        case 'Steel':
                            state.steelNames = response.data && response.data.length > 0 ? response.data : [];
                            break;
                    }
                    return await new Promise((resolve) => {
                        setTimeout(() => {
                            resolve(true)
                        }, 10)
                    })
                })
                .catch(async function (error) {
                    console.log(error);
                    await Promise.resolve(error);
                });
        },
        async getMaterialDetails({ state, dispatch, getters, commit }, [materialType, materialName, bayXdim, bayYdim, sector]) {
            //console.log("***** materials->getMaterialDetails()")
            // make call to BE for database contents
            if (materialType === 'Timber' && materialName === '') {
                materialName = state.selectedTimberMaterial.textVal;
            }            

            await axios({
                method: 'post',
                url: process.env.VUE_APP_API_URL + '/Values/MaterialDetails',
                data: {
                    Value1: materialType,
                    Value2: materialName,
                    Value3: bayXdim.toString(),
                    Value4: bayYdim.toString(),
                    Value5: sector,
                }
            })
                .then(async function (response) {

                    switch (materialType) {
                        case 'Timber':
                            state.timberDetails = response.data;
                            state.selectedTimberMaterial.text = response.data[0].MaterialName;
                            state.selectedTimberMaterial.textVal = response.data[0].SystemTypeAbridgedName;                            
                            for (let i = 0, l = state.timberNames.length; i < l; ++i) {
                                if (state.timberNames[i].textVal === response.data[0].SystemTypeAbridgedName) {
                                    state.timberNames[i].text = response.data[0].MaterialName;
                                    break;
                                }
                            }                            
                            break;
                        case 'Concrete':
                            state.concreteDetails = response.data;
                            state.selectedConcreteMaterial.text = response.data[0].MaterialName
                            state.selectedConcreteMaterial.textVal = response.data[0].SystemTypeAbridgedName
                            break;
                        case 'Steel':
                            state.steelDetails = response.data;
                            break;
                    }
                    //await commit('setMaterialUri', materialType)

                    //console.log("***** materials->getMaterialDetails() finished")
                    return await new Promise((resolve) => {
                        setTimeout(() => {
                            resolve(true)
                        }, 10)
                    })
                })
                .catch(function (error) {
                    console.log(error);    
                });
        },
        async setMaterialUri({ state, dispatch, getters, commit }, [materialType]) {
            var drawingName = '';
            //var materialType = materialTypeValue[0];
            if (materialType === 'Timber') {
                drawingName = state.timberDetails && state.timberDetails.length > 0 ? state.timberDetails[0]['DrawingName'] : '';
                //drawingName = "Mesh1.obj"
            } else if (materialType === 'Concrete') {
                drawingName = state.concreteDetails && state.concreteDetails.length > 0 ? state.concreteDetails[0]['DrawingName'] : '';
                //drawingName = "Mesh1.obj"
            } else if (materialType === 'Steel') {
                drawingName = state.steelDetails && state.steelDetails.length > 0 ? state.steelDetails[0]['DrawingName'] : '';
            } else if (materialType === 'materials.mtl') {
                drawingName = materialType
            }

            await axios.post(state.appApiUrl + '/FileStorage/FileUri', { FolderName: 'obj', FileName: drawingName })
                .then(async response => {
                    //console.log(response);
                    if (materialType === 'Timber') {
                        state.timberObjectUri = response.data;
                    } else if (materialType === 'Concrete') {
                        state.concreteObjectUri = response.data;
                    } else if (materialType === 'Steel') {
                        state.steelObjectUri = response.data;
                    } else if (materialType === 'materials.mtl') {
                        state.objMaterialsUri = response.data;
                    }
                    //await Promise.resolve(response);

                    return await new Promise((resolve) => {
                        setTimeout(() => {
                            resolve(true)
                        }, 10)
                    })

                })
                .catch(error => {
                    console.log(error);
                })
        },
        // for changing only the Timber material selected while all other settings stay the same
        async updateMaterialDetails({ state, dispatch, getters, commit }, [materialType]) {
            //console.log("***** materials->updateMaterialDetails()")
            try {
                switch (materialType) {
                    case 'Timber':
                        state.materialName = await Promise.resolve(state.selectedTimberMaterial.textVal);
                        break;
                    case 'Concrete':
                        state.materialName = await Promise.resolve('');
                        break;
                    case 'Steel':
                        state.materialName = await Promise.resolve('');
                        break;
                }

                ////console.log("***** materials->getMaterialDetails()")
                // make call to BE for database contents
                await axios({
                    method: 'post',
                    url: process.env.VUE_APP_API_URL + '/Values/MaterialDetails',
                    data: {
                        Value1: materialType,
                        Value2: state.materialName,
                        Value3: state.selectedBayX,
                        Value4: state.selectedBayY,
                        Value5: state.selectedSector
                    }
                })
                .then(async function (response) {
                    //console.log(response);
                    if (materialType === 'Timber') {
                        state.timberDetails = response.data;
                        state.selectedTimberMaterial.text = response.data[0].MaterialName;
                        state.selectedTimberMaterial.textVal = response.data[0].SystemTypeAbridgedName;
                        for (let i = 0, l = state.timberNames.length; i < l; ++i) {
                            if (state.timberNames[i].textVal === response.data[0].SystemTypeAbridgedName) {
                                state.timberNames[i].text = response.data[0].MaterialName;
                                break;
                            }
                        }                       
                    } else if (materialType === 'Concrete') {
                        state.concreteDetails = response.data
                        state.selectedConcreteMaterial.text = response.data[0].MaterialName
                    } else if (materialType === 'Steel') {
                        state.steelDetails = response.data
                    }
                    //await commit('setMaterialUri', materialType)

                    return await new Promise((resolve) => {
                        setTimeout(() => {
                            resolve(true)
                        }, 10)
                    })
                })
                .catch(async function (error) {
                    console.log(error);
                    return await new Promise((resolve) => {
                        setTimeout(() => {
                            resolve(false)
                        }, 10)
                    })
                });
            } finally {
            }
        },
        // itemColumn = BayUnitWeight, MaterialEfficiency, Cost, Carbon, OverallStructuralDepth
        async setItemRange({ state, dispatch, getters, commit }, [itemColumn]) {
            //console.log("***** materials->setItemRange()")
            //console.log(itemColumn)
            state.loadingData = true;
            // make call to BE for database contents
            await axios({
                method: 'post',
                url: process.env.VUE_APP_API_URL + '/Values/ItemRange',
                data: {
                    Value1: itemColumn,
                    Value2: state.selectedBayX,
                    Value3: state.selectedBayY,
                    Value4: state.selectedSector,
                    Value5: state.selectedTimberMaterial.textVal
                }
            })
                .then(async function (response) {
                    //console.log(response);
                    await Promise.resolve(state.itemMinValue[0][itemColumn] = parseFloat(response.data[0].Min));
                    await Promise.resolve(state.itemMaxValue[0][itemColumn] = parseFloat(response.data[0].Max));
                    await Promise.resolve(state.itemMaxFactor[0][itemColumn] = parseFloat(state.chartMaxUnit) / state.itemMaxValue[0][itemColumn]);
                    /*
                    return await new Promise((resolve) => {
                        setTimeout(() => {
                            resolve(true)
                        }, 10)
                    })
                    */
                })
                .catch(async function (error) {
                    console.log(error);
                    return await new Promise((resolve) => {
                        setTimeout(() => {
                            resolve(false)
                        }, 10)
                    })
                });

        },
        async setMaterialRating({ state, dispatch, getters, commit }) {
            //console.log("***** materials->setMaterialRating()")

            var detailTimberValue = state.timberDetails && state.timberDetails.length > 0 ? state.timberDetails[0][key] : undefined;
            var detailConcreteValue = state.concreteDetails && state.concreteDetails.length > 0 ? state.concreteDetails[0][key] : undefined;
            var detailSteelValue = state.steelDetails && state.steelDetails.length > 0 ? state.steelDetails[0][key] : undefined;

            if (detailTimberValue !== undefined && detailConcreteValue !== undefined && detailSteelValue !== undefined) {
                var keyLookup = state.itemReverseLookup[0][key];
                var itemIndex = state.itemOrder.indexOf(keyLookup);
                var itemRank = state.itemBestRank[itemIndex];

                var itemArray = [];
                itemArray.push(
                    { 'materialType': 'Timber', 'value': Number(detailTimberValue) },
                    { 'materialType': 'Concrete', 'value': Number(detailConcreteValue) },
                    { 'materialType': 'Steel', 'value': Number(detailSteelValue) }
                )
                state.itemRatingSorted = [];
                if (itemRank === 'Min') {
                    state.itemRatingSorted = itemArray.sort((a, b) => a.value - b.value);
                } else { //Max
                    state.itemRatingSorted = itemArray.sort((a, b) => b.value - a.value);
                }
            }

            //console.log("***** materials->setMaterialRating() finished")
            return await new Promise((resolve) => {
                setTimeout(() => {
                    resolve(true)
                }, 100)
            })
        },
        // itemColumn = BayUnitWeight, MaterialEfficiency, Cost, Carbon, OverallStructuralDepth
        //async setChartDataByMaterialType({ state, dispatch, getters, commit }, [materialType]) {
        setChartDataByMaterialType: ({ state, dispatch, getters, commit }, [materialType]) => {
            //console.log("***** materials->setChartDataByMaterialType()")
            //console.log(materialType)
            if (!state.itemLookup || state.itemLookup.length == 0) { return; }
            for (var key in state.itemLookup[0]) {
                //for (var key of Object.keys(state.itemLookup[0])) {
                var name = state.itemLookup[0][key];
                var trueValue;
                switch (materialType) {
                    case 'Timber':
                        trueValue = state.timberDetails && state.timberDetails.length > 0 ? state.timberDetails[0][name] : undefined;
                        break;
                    case 'Concrete':
                        trueValue = state.concreteDetails && state.concreteDetails.length > 0 ? state.concreteDetails[0][name] : undefined;
                        break;
                    case 'Steel':
                        trueValue = state.steelDetails && state.steelDetails.length > 0 ? state.steelDetails[0][name] : undefined;
                        break;
                }
                if (trueValue !== undefined) {
                    var i = state.itemOrder.indexOf(key);
                    var itemRank = state.itemBestRank[i];
                    trueValue = isNaN(parseFloat(trueValue)) ? 0 : parseFloat(trueValue)
                    var detailValue = trueValue.toString();
                    var comma = state.itemComma[0][key];
                    if (comma === true) {
                        detailValue = detailValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }
                    var prefix = state.itemPrefix[0][key];
                    if (prefix !== '') {
                        detailValue = prefix.trim() + ' ' + detailValue;
                    }
                    var suffix = state.itemSuffix[0][key];
                    if (suffix !== '') {
                        if (suffix.substring(0, 1) !== '/') {
                            detailValue = detailValue + ' '
                        }
                        detailValue = detailValue + suffix.trim();
                    }

                    var itemValue = state.itemMaxFactor[0][key] * trueValue;

                    // this was disabled by request so the best is inside the chart rather than outside
                    /*
                    var itemValue = 0;
                    if (itemRank === 'Min') {
                        if (trueValue === state.itemMinValue[0][key]) {
                            itemValue = state.chartMaxUnit;
                        }
                        else {
                            itemValue = state.itemMaxFactor[0][key] * (state.itemMaxValue[0][key] - (trueValue - state.itemMinValue[0][key]));
                        }
                    } else {
                        itemValue = state.itemMaxFactor[0][key] * trueValue;
                    }
                    */
                    switch (materialType) {
                        case 'Timber':
                            state.timberChartData[i] = itemValue
                            state.timberChartTrueValue[i] = detailValue
                            //console.log(state.timberChartData);
                            //console.log(state.timberChartTrueValue);
                            break;
                        case 'Concrete':
                            state.concreteChartData[i] = itemValue
                            state.concreteChartTrueValue[i] = detailValue
                            break;
                        case 'Steel':
                            state.steelChartData[i] = itemValue
                            state.steelChartTrueValue[i] = detailValue
                            break;
                    }
                    i++;
                }
            }

            //console.log("***** materials->setChartDataByMaterialType() finished")
        },
        setBestAsCurrentTimber({ state, dispatch, getters, commit }) {
            if (state.isAutoSelectEnabled) {
                state.selectedTimberMaterial = state.bestTimberMaterial;
            }
        },
        // being used for database content retrieval
        async setBestTimberMaterial({ state, dispatch, getters, commit }) {
            if (state.isAutoSelectEnabled) {
                //console.log("***** materials->setBestTimberMaterial()")
                // make call to BE for database contents
                await axios({
                    method: 'post',
                    url: process.env.VUE_APP_API_URL + '/Values/BestTimberMaterial',
                    data: {
                        Value1: state.selectedBayX.toString(),
                        Value2: state.selectedBayY.toString(),
                        Value3: state.selectedSector.toString(),
                        Value4: state.isAutoSelectEnabled.toString()
                    }
                })
                    .then(async function (response) {
                        state.bestTimberMaterial = response.data && response.data.length > 0 ? response.data[0] : {};

                        return await new Promise((resolve) => {
                            setTimeout(() => {
                                resolve(true)
                            }, 10)
                        })
                    })
                    .catch(async function (error) {
                        console.log(error);
                        await Promise.resolve(error);
                    });
            } else {
                await Promise.resolve(state.isAutoSelectEnabled)
            }
        },
        async setOptionSummary({ state, dispatch, getters, commit }) {
            //console.log("***** materials->setOptionSummary()")
            // make call to BE for database contents
            await axios({
                method: 'post',
                url: process.env.VUE_APP_API_URL + '/Values/OptionSummary',
                data: {
                    Value1: state.selectedBayX.toString(),
                    Value2: state.selectedBayY.toString(),
                    Value3: state.selectedSector.toString(),
                }
            })
                .then(async function (response) {
                    state.optionSummary = response.data;

                    //console.log("***** materials->getOptionSummary() finished")
                    return await new Promise((resolve) => {
                        setTimeout(() => {
                            resolve(true)
                        }, 50)
                    })
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }
}

/*
        async getMaterials() {
            // make call to BE for database contents
            await axios.get('http://localhost:44300/api' + '/Values/Materials')
                .then(async function (response) {
                    //console.log(response.data);
                    return Promise.resolve(response.data);
                })
                .catch(async function(error) {
                    console.log(error);
                    await Promise.resolve(error);
                })
        }
    */

/*
import shop from "@/api/shop";

export default {
    namespaced: true,

    state: {
        items: []
    },

    getters: {
        availableProducts(state, getters) {
            return state.items.filter(product => product.inventory > 0)
        },

        productIsInStock() {
            return (product) => {
                return product.inventory > 0
            }
        }
    },

    mutations: {
        setProducts(state, products) {
            // update products
            state.items = products
        },

        decrementProductInventory(state, product) {
            product.inventory--
        }
    },

    actions: {
        fetchProducts({ commit }) {
            return new Promise((resolve, reject) => {
                // make the call
                // call setProducts mutation
                shop.getProducts(products => {
                    commit('setProducts', products)
                    resolve()
                })
            })
        }
    }
}
*/