








export default {
    name: "app",
    components: {
        Header: () => import('./components/Header.component.vue'),
        SubHeader: () => import('./components/SubHeader.component.vue'),
        MainContent: () => import('./components/MainContent.component.vue')
    }
}
