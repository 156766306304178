// import dependency to handle HTTP request to our back end
import axios from 'axios'
import Vuex from 'vuex'
import Vue from 'vue'
import datafields from './modules/datafields'
import materials from './modules/materials'
import misc from './modules/misc'
import AuthenticationModel from '../assets/models/AuthenticationModel'

// load Vuex
Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        datafields,
        materials,
        misc
    },
    state: {
        data: null,
        auth: new AuthenticationModel(),
        posts: []
    },
    getters: {
        isLoggedIn: state => !!state.auth.Token,
        authStatus: state => state.auth.Status
    },
    actions: {
       enter({ commit }) {
            commit('enter');
        }        
    },
    mutations: {
        authRequest (state) {
            state.auth.Status = 'loading'
        },
        authError (state) {
            state.auth.Status = 'error'
        },
        authSuccess (state, user) {
            state.auth.Status = 'success'
            state.auth.Token = user.token
            state.auth.User = user
        },
        enter(state) {
            state.auth.User = 'woodlabUser';
            state.auth.Status = 'success';
            state.auth.Token = 'ok';
        }
    }
})
