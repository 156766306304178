import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css';
import App from './App.vue'
import router from './router'
import store from './store'
import VueSimpleAlert from 'vue-simple-alert'
import Axios from 'axios'

import { library } from '@fortawesome/fontawesome-svg-core'
// internal icons
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import '@mdi/font/css/materialdesignicons.css'
import VueTour from 'vue-tour'

require('vue-tour/dist/vue-tour.css')

Vue.prototype.$http = Axios
Vue.prototype.$timberURL = process.env.VUE_APP_TIMBER_URL;

Vue.use(Vuetify)
Vue.use(VueSimpleAlert, { reverseButtons: true })
Vue.use(VueTour)

library.add(fas)
Vue.component('vue-fontawesome', FontAwesomeIcon)

Vue.prototype.$vueEventBus = new Vue()
Vue.config.productionTip = false

new Vue({
    store,
    router,
    vuetify: new Vuetify(),
    render: h => h(App)
}).$mount('#app')
