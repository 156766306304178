import Vue from 'vue'
import Router, { RouteConfig } from 'vue-router'
import Home from '../components/Home.component.vue'
import EmailVerify from '../components/EmailVerify.component.vue'

Vue.use(Router)
const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'home',
        component: Home       
    },   
    {
        path: '/email_verify/:id',
        name: 'EmailVerify',
        component: EmailVerify
    },   
    {
        path: '*', redirect: '/'        
    }  
]

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes
})

export default router
