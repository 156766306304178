import axios from 'axios'

export default {
    namespaced: true,
    data: () => ({
        appApiUrl: process.env.VUE_APP_API_URL,
    }),
    state: {
        storageKey: 'reg-timber',
        userIP: null,
        userCountry: ''
    },
    getters: {               
        getUser_IP: (state) => {
            return state.userIP;
        },
        getRegistrationStore(state) {
            return new Promise((resolve) => {   
                let item = localStorage.getItem(state.storageKey);            
                if (!item) return resolve(null); 

                item = JSON.parse(item);
                try {                
                    if (item.expiry && new Date().getTime() > new Date(item.expiry).getTime()) {                    
                        localStorage.removeItem(state.storageKey);
                        return resolve(null); 
                    }
                } catch (e) {
                    localStorage.removeItem(state.storageKey);
                    return resolve(null); 
                }            
                return resolve(item.value); 
            });
        }
    },
    mutations: {
    },
    actions: {             
        setRegistrationStore({ state }, { val }) {
            try {
                var expiryDate = val == '1' ? null : new Date(new Date().getTime() + (7 * 24 * 60 * 60 * 1000)); //7 days from now;            
                let item = {
                    value: val,
                    expiry: expiryDate
                };
                localStorage.setItem(state.storageKey, JSON.stringify(item));
            } catch (e) {
                console.log(e);
            }            
        },
        userDetails({ state }) {
            return new Promise((resolve) => {   
                var url = 'https://json.geoiplookup.io';
                if (state.userIP) {
                    url += '/' + state.userIP;
                }
                axios.get(url).then(function (res) {
                    if (res && res.data) {
                        state.userIP = res.data.ip;
                        state.userCountry = res.data.country_name;       
                    } 
                    resolve(true);    
                }).catch(function (error) {                        
                    console.log(error);
                    resolve(false);     
                });                  
            });
        },
        user_IP({ state, dispatch, getters, commit }) {     
            var _t = this;
            return new Promise((resolve) => {   
                try {
                    axios.get("https://api.ipify.org/?format=json").then(function (res) {
                        if (res && res.data) {
                            state.userIP = res.data.ip;                            
                        } 
                        resolve(dispatch('userDetails'));
                    }).catch(function (error) {                        
                        console.log(error);
                        resolve(dispatch('userDetails'));     
                    });
                } catch (e) {
                    resolve(dispatch('userDetails'));                    
                }      
            });
        }
    }
}
