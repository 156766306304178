var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { "fill-height": "", fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { align: "center" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "verify-card d-flex align-center justify-center",
                  attrs: { dark: "", "max-width": "500", height: "300" }
                },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center", "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { align: "right", cols: "3" } },
                            [
                              _vm.working
                                ? _c("v-progress-circular", {
                                    attrs: {
                                      size: 50,
                                      color: "primary",
                                      indeterminate: ""
                                    }
                                  })
                                : _c(
                                    "div",
                                    [
                                      _vm.verifySuccess
                                        ? _c("v-img", {
                                            attrs: {
                                              src: _vm.verifyImg,
                                              width: "64px"
                                            }
                                          })
                                        : _c(
                                            "v-icon",
                                            { attrs: { "x-large": "" } },
                                            [_vm._v("mdi-alert-box-outline")]
                                          )
                                    ],
                                    1
                                  )
                            ],
                            1
                          ),
                          _c("v-col", { attrs: { cols: "9" } }, [
                            _vm.working
                              ? _c("span", [
                                  _vm._v(
                                    " Please wait as we verify your email... "
                                  )
                                ])
                              : _c("span", [_vm._v(_vm._s(_vm.apiMsg))])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }