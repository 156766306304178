import axios from 'axios'

export default {
    namespaced: true,
    data: () => ({
        //appApiUrl: process.env.VUE_APP_API_URL,
        appApiUrl: process.env.VUE_APP_API_URL,
    }),
    state: {
        // must specify initial value
        gridWidthMin: '3',
        gridWidthMax: '10',
        gridWidthStep: '0.5',
        gridWidthValues: ['3', '3.5', '4', '4.5', '5', '5.5', '6', '6.5', '7', '7.5', '8', '8.5', '9', '9.5', '10'],
        gridLengthMin: '3',
        gridLengthMax: '10',
        gridLengthStep: '0.5',
        gridLengthValues: ['3', '3.5', '4', '4.5', '5', '5.5', '6', '6.5', '7', '7.5', '8', '8.5', '9', '9.5', '10'],

        // json list structured as {'text': 'value'}
        sectors: [
            { 'text': '' }
        ],
        selectedBayX: '3',
        seldctedBayY: '3',
        selectedSector: '',
        infoDetails: [{ 'title': '' }, { 'contents': '' }],
    },
    getters: {
        getGridWidthMin: (state, dispatch, getters) => {
            return state.gridWidthMin;
        },
        getGridWidthMax: (state, dispatch, getters) => {
            return state.gridWidthMax;
        },
        getGridWidthStep: (state, dispatch, getters) => {
            return state.gridWidthStep;
        },
        getGridWidthValues: (state, dispatch, getters) => {
            return state.gridWidthValues;
        },
        getGridLengthMin: (state, dispatch, getters) => {
            return state.gridLengthMin;
        },
        getGridLengthMax: (state, dispatch, getters) => {
            return state.gridLengthMax;
        },
        getGridLengthStep: (state, dispatch, getters) => {
            return state.gridLengthStep;
        },
        getGridLengthValues: (state, dispatch, getters) => {
            return state.gridLengthValues;
        },
        getSectors: (state, dispatch, getters) => {
            //console.log("***** datafields->getSectors()")
            return state.sectors;
        },
        getActiveSectorName: (state, dispatch, getters) => {
            //console.log("***** datafields->getActiveSectorName()")
            return state.selectedSector;
        }
    },
    mutations: {
        /*
        setSector(value) {
            $store.dispatch('materials/setSector', value, { root: true })
        }
        */
    },
    actions: {
        // being used for database content retrieval
        async getSectorsList({ state, context, getters, commit }) {
            //console.log("***** datafields->getSectorsList()")
            // make call to BE for database contents
            await axios({
                method: 'get',
                url: process.env.VUE_APP_API_URL + '/Values/Sectors',
                data: {}
            })
                .then(async function (response) {
                    state.sectors = response.data;
                    for (var i = 0; i < response.data.length; i++) {
                        if (response.data[i].selectedItem !== '') {
                            state.selectedSector = response.data[i].selectedItem;
                            i = response.data.length;
                        }
                    }
                    if (state.selectedSector === '') {
                        state.selectedSector = response.data[0].text;
                    }
                    return await new Promise((resolve) => {
                        setTimeout(() => {
                            resolve(true)
                        }, 10)
                    })
                    //console.log(state.sectors)
                    //console.log("***** datafields->getSectorsList() finished")
                })
                .catch(async function (error) {
                    console.log(error);
                    await Promise.resolve(error);    
                });
        },
        async getBayDimensions({ state, dispatch, getters, commit }) {
            ////console.log("***** datafields->getBayDimensions()")
            // make call to BE for database contents
            await axios({
                method: 'get',
                url: process.env.VUE_APP_API_URL + '/Values/BayDimensions',
                data: {}
            })
                .then(async function (response) {
                    ////console.log(response);
                    state.gridWidthMin = response.data.GridWidthMin.toString(),
                    state.gridWidthMax = response.data.GridWidthMax.toString(),
                    state.gridLengthMin = response.data.GridLengthMin.toString(),
                        state.gridLengthMax = response.data.GridLengthMax.toString()
                    return await new Promise((resolve) => {
                        setTimeout(() => {
                            resolve(true)
                        }, 10)
                    })
                })
                .catch(async function (error) {
                    console.log(error);
                    await Promise.resolve(error);
                });
        },
        // being used for database content retrieval
        async setInformation({ state, dispatch, getters, commit }) {
            // make call to BE for database contents
            await axios({
                method: 'get',
                url: process.env.VUE_APP_API_URL + '/Values/Information',
                data: {}
            })
                .then(async function (response) {
                    //console.log(response);
                    state.infoDetails = response.data;

                    return await new Promise((resolve) => {
                        setTimeout(() => {
                            resolve(true)
                        }, 10)
                    })
                })
                .catch(async function (error) {
                    console.log(error);
                    await Promise.resolve(error);
                });
        },
        async userContactInfo({ state, dispatch, getters, commit },
            [firstName, lastName, userEmail, jobTitle, phoneNumber, userIP, companyName, country, message, userId, registered, subscribed]) {
            //console.log("***** datafields->userContactInfo()")
            // make call to BE for database insert/update
            await axios({
                method: 'post',
                url: process.env.VUE_APP_API_URL + '/Values/UserContactInfo',
                data: {
                    Value1: firstName,
                    Value2: lastName,
                    Value3: userEmail,
                    Value4: jobTitle,
                    Value5: phoneNumber,
                    Value6: userIP,
                    Value7: companyName,
                    Value8: country,
                    Value9: message,
                    Value10: userId,
                    Value11: registered,
                    Value12: subscribed,
                }
            })
            .then(async function (response) {

                //response.data[0].ErrorNumber      // 0 = successful, -1 = failure, > 0 = sql error
                //response.data[1].ResultMessage    
                    // 0	User was successfully saved.
                    // 0	User was successfully updated.
                    // -1   Combined UserId and UserEmail does not exist.
                    // -1   User email is already used.
                    // -1	User email is mandatory.
                    //515	Cannot insert the value NULL into column 'FirstName', table 'timberdb.dbo.UserContactInfo'; column does not allow nulls. UPDATE fails.
                    //515	Cannot insert the value NULL into column 'UserIP', table 'timberdb.dbo.UserContactInfo'; column does not allow nulls. INSERT fails.

                //console.log("***** datafields->userContactInfo() finished")
                return await new Promise((resolve) => {
                    setTimeout(() => {
                        resolve(true)
                    }, 10)
                })
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        async getUser({ state, dispatch, getters, commit }, [val, type]) {
            return await new Promise((resolve) => {
                axios({
                    method: 'post',
                    url: process.env.VUE_APP_API_URL + '/Values/User',
                    data: {
                        Value: val,
                        Type: type
                    }
                }).then(async function (response) {                                                                  
                    return resolve(response);
                }).catch(function (error) {
                    console.log(error);
                    return resolve(null);                    
                });
            });
        },
        async verifyUser({ state, dispatch, getters, commit }, [val]) {
            return await new Promise((resolve) => {
                axios({
                    method: 'post',
                    url: process.env.VUE_APP_API_URL + '/Values/VerifyUser/' + val
                }).then(async function (response) {          
                    if (response && response.data && response.data.length > 0) {
                        return resolve(response.data[0]);
                    } else {
                        return resolve({ErrorNumber: -1, ResultMessage: 'An unknown error has occurred. Please try again later, or contact WSP Support'});
                    }                                                                   
                }).catch(function (error) {
                    console.log(error);
                    if (error && error.data && error.data.length > 0) {
                        return resolve(error.data[0]);
                    } else {
                        return resolve({ErrorNumber: -1, ResultMessage: 'An unknown error has occurred. Please try again later, or contact WSP Support'});
                    }                  
                });
            });
        }
    }
}
